
import {computed, defineComponent, inject, onMounted, Ref, ref, watch} from "vue";
import store from "@/store";
import {TApi} from "@/api/types";
import {TGamesClassifierItem,TGamesAllowedCreate} from "@/api/modules/game/types";

export default defineComponent({
    name: "AllowUserGame",
    props: {
        userId: {
            type: String
        }
    },
    setup(props) {
        onMounted(() => {
            if (props.userId) {
                loadAllowedGames(props.userId);
            }
        });

        const  userGames: Ref<[Array<TGamesClassifierItem>, Array<TGamesClassifierItem>]> = ref([[], []]) as unknown as Ref<[Array<TGamesClassifierItem>, Array<TGamesClassifierItem>]>;
        const gamesClassifier = computed(() => store.getters['games/getGamesClassifier']);
        const api: TApi = inject('api') as TApi;
        const blocked = inject('blocked', ref(false));

        const setUserGames = (val: TGamesClassifierItem[]) => {
            userGames.value = [[], []]
            const allowedGames = [...val];
            const noAllowedGames = [...gamesClassifier.value];
            noAllowedGames.forEach((res) => {
                if (!allowedGames.some(item => item.id === res.id)) {
                    userGames.value[1].push(res)
                }
            });
            userGames.value[0] = [...val];
            blocked.value = false;
        }

        const loadAllowedGames = (userId: string) => {
            blocked.value = true;
            api.apiGame.getAllowedGames(userId).then((res) => {
                setUserGames(res.data as Array<TGamesClassifierItem>);
            });
        }

        watch(() => props.userId, (newVal, oldVal) => {
            if (newVal) {
                loadAllowedGames(newVal);
            }
        });

        const saveChange = async () => {
            const val: TGamesAllowedCreate = {user_id: props.userId ?? '', game_id: userGames.value[0].map(res => res.id)}
            await api.apiGame.setAllowedGames(val);
            if (props.userId) {
                loadAllowedGames(props.userId);
            }
        }

        return {
            userGames,
            saveChange
        }
    }
});
