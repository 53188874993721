
import {computed, defineComponent, inject, onBeforeMount, onMounted, Ref, ref, nextTick, ComputedRef} from 'vue';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {TColHeaders, TObject} from '@/source/type/types';
import {IUser} from "@/store/modules/users/types";
import {formatDate} from "@/source/functions/formatDate";

export default defineComponent({
    name: 'CustomTable',
    methods: {formatDate},
    props: {

    },
    setup() {
        onBeforeMount(() => {
            filters.value = makeFilters();
            initfilters();
        });

        onMounted(async () => {
            lazyParams.value = {
                first: 0,
                rows: 10,
                sortField: null,
                sortOrder: null,
                filters: filters.value
            };
        });

        const cardTableUsers = ref();
        const dt = ref();
        const lazyParams: Ref<any> = ref({});
        const headers: TColHeaders[] = inject('TableHeaders') as TColHeaders[];
        const tableItems: ComputedRef<IUser[] | []> = inject('TableItems') as ComputedRef<IUser[] | []>;
        const totalRecords: Ref<number> = inject('TotalRecords', ref(0));
        const loading: Ref<boolean> = inject('TableLoading', ref(false));
        const getPage = inject('getPage')
        const loadItems = inject('loadItems') as any;

        let filters = ref({});
        const makeFilters = () => {
            let temp: TObject = {};
            temp['global'] = {value: null, matchMode: FilterMatchMode.CONTAINS};
            headers.forEach((res: any) => {
                if (res.type === 'col') {
                    let field: string = res.field;
                    temp[field] = {value: null, matchMode: FilterMatchMode.STARTS_WITH};
                }
            });
            return temp
        };
        const initfilters = () => {
            let temp: TObject = {};
            temp['global'] = {value: null, matchMode: FilterMatchMode.CONTAINS};
            headers.forEach((res: any) => {
                if (res.type === 'col') {
                    let field: string = res.field;
                    temp[field] = {
                        operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
                    };
                }
            });
            filters.value = temp;
        };

        const globalFilterFields = computed(() => {
            return headers.map((res: any) => res.field)
        });
        const setLazyPage = () => {
            lazyParams.value.page = isNaN(lazyParams.value.page) ? lazyParams.value.page = 0 : lazyParams.value.page;
        };

        const makeFilter = () => {
            let filters: TObject = {};
            Object.keys(lazyParams.value.filters).forEach((val: string) => {
                let filter = lazyParams.value.filters[val];
                if (val === 'global') {
                    if (filter.value) {
                        filters[val] = filter.value;
                    }
                } else {
                    if (filter.constraints.length > 0) {
                        let value: any = [];
                        filter.constraints.forEach((res: TObject) => {
                            if (res.value) {
                                value.push(res.value);
                            }
                        });
                        if (value.length === 1 ) {
                            filters[val] = value[0];
                        } else if (value.length > 1) {
                            filters[val] = value;
                        }
                    }
                }
            });
            return filters
        }

        const onPage = async (event: any) => {
            lazyParams.value = event;
            setLazyPage();
            await loadItems(lazyParams.value.page + 1, lazyParams.value.rows, makeFilter());
        };

        const onSort = onPage;

        const onFilter = async () => {
            lazyParams.value.filters = filters;
            setLazyPage();
            await loadItems(lazyParams.value.page + 1, lazyParams.value.rows, makeFilter());
        };

        const applyFilter = (callBack: any) => {
            callBack();
        };
        const cleanFilter = (callBack: any) => {
            callBack();
        };

        const clearFilter = async () => {
            initfilters();
            if ('multiSortMeta' in lazyParams.value) {
                delete lazyParams.value.multiSortMeta
            }
            if ('filterMatchModes' in lazyParams.value) {
                delete lazyParams.value.filterMatchModes
            }
            if ('originalEvent' in lazyParams.value) {
                delete lazyParams.value.originalEvent
            }
            lazyParams.value.filters = filters.value;
            setLazyPage();
            await loadItems(lazyParams.value.page + 1, lazyParams.value.rows, makeFilter())
        };

        const showAddUser: Ref = inject('showAddUser') as Ref;
        const addUser = () => {
            showAddUser.value = !showAddUser.value;
        }

        return {
            headers,
            tableItems,
            filters,
            totalRecords,
            loading,
            globalFilterFields,
            getPage,
            dt,
            cardTableUsers,
            onPage,
            onSort,
            onFilter,
            applyFilter,
            cleanFilter,
            clearFilter,
            addUser
        }
    }
});
