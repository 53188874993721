
import {computed, ComputedRef, defineComponent, onMounted, provide, Ref, ref, watch} from 'vue';
import store from "@/store";
import checkErrorCode from "@/source/functions/responseHandler";
import {TObject, TUndefinedOrString} from "@/source/type/types";
import {useToast} from "primevue/usetoast";
import CustomTable from "@/components/table/CustomTable.vue";
import AddUser from "@/components/users/AddUser.vue";
import {IUser} from "@/store/modules/users/types";
import AllowUserGame from "@/components/users/AllowUserGame.vue";
import UsersStatistick from "@/components/users/UsersStatistick.vue";


export default defineComponent({
    name: 'Users',
    components: {
        UsersStatistick,
        AllowUserGame,
        CustomTable,
        AddUser
    },
    setup() {
        onMounted(() => {
            loadingData(1, 10);
        });

        // const buildData: any = inject('buildData');
        const showAddUser = ref(false);
        provide('showAddUser', showAddUser)
        const toast = useToast();
        const isloading = ref(false);
        provide('TableLoading', isloading);
        const isError = ref(false);
        const userKey = ref(0);
        const blocked = ref(false);
        provide('blocked', blocked);
        /**
         * Загрузка пользователей
         * @param page
         * @param size
         * @param filters
         */
        const loadingData = async (page = 1, size = 10, filters: TObject | null = null) => {
            blocked.value = true;
            isloading.value = true;
            isError.value = false;
            const payload: TObject = {page: page, size: size}
            if (filters) {
                payload.filters = filters;
            }
            await store.dispatch('users/loadUsers', payload).catch(async (err) => {
                if (err?.response?.status === 401) {
                    if (store.getters['login/getAccessTokenExpire'] && store.getters['login/getAccessToken']) {
                        const end_date = new Date(store.getters['login/getAccessTokenExpire']);
                        const currentDate = new Date(new Date().toISOString());
                        if (currentDate > end_date) {
                            await store.dispatch('login/refresh');
                            store.dispatch('users/loadUsers', payload).catch((err) => {
                                isError.value = true;
                                toast.add(checkErrorCode(err.response.status));
                            });
                        }
                    } else {
                        store.commit('login/signError');
                        isError.value = true;
                        toast.add(checkErrorCode(err.response.status));
                    }
                }
            }).finally(() => {
                isloading.value = false;
                blocked.value = false;
            });
        };
        provide('loadItems', loadingData);

        const users: ComputedRef<IUser[] | []> = computed(() => store.getters['users/getUsers'] || []);
        provide('TableItems', users);
        const totalRecords = computed(() => store.getters['users/getCount'] || 0);
        provide('TotalRecords', totalRecords);

        const isEdit = ref(false);
        const userId: Ref<TUndefinedOrString> = ref(undefined) as Ref<TUndefinedOrString>;
        const getPage = (id: string) => {
            userId.value = id;
            isEdit.value = true;
            showAddUser.value = true;
        };
        provide('getPage', getPage);

        const genderList = {
            male: 'мужской',
            female: 'женский'
        };

        const boolList = {
            true: 'Да',
            false: 'Нет'
        };

        const headers = ref([
            { id: '1',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'lastname',
                title: 'Фамилия',
                type: 'col',
                headerStyle: "max-width: 10rem;",
                bodyStyle: "max-width: 10rem;"
            },
            { id: '2',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'firstname',
                title: 'Имя',
                type: 'col',
                headerStyle: "max-width: 10rem;",
                bodyStyle: "max-width: 10rem;"
            },
            { id: '3',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'middlename',
                title: 'Отчество',
                type: 'col',
                headerStyle: "max-width: 10rem;",
                bodyStyle: "max-width: 10rem;"
            },
            { id: '4',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'gender',
                title: 'Пол',
                type: 'col',
                serialize: {...genderList},
                headerStyle: "max-width: 8rem;",
                bodyStyle: "max-width: 8rem;"
            },
            { id: '5',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'birthday',
                filterType: 'date',
                title: 'Дата рождения',
                type: 'col'
            },
            { id: '6',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'email',
                title: 'Почта',
                type: 'col'
            },
            { id: '7',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'phone',
                title: 'Телефон',
                type: 'col'
            },
            { id: '8',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'login',
                title: 'Логин',
                type: 'col'
            },
            { id: '9',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'is_locked',
                title: 'Заблокирован',
                type: 'col',
                serialize: {...boolList}
            },
            // { id: '10',
            //   sortable: false,
            //   showFilterMatchModes: false,
            //   showFilterOperator: false,
            //   showAddButton: false,
            //   field: 'is_admin',
            //   title: 'Администратор',
            //   type: 'col',
            //   serialize: {...boolList}
            // },
            { id: '10',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'is_admin',
                title: 'Роль',
                type: 'col',
                serialize: (val: TObject) => {
                    if (val.is_admin && val.is_superadmin) {
                        return 'суперадминистратор'
                    } else if (val.is_admin && !val.is_superadmin) {
                        return 'администратор'
                    } else {
                        return 'пользователь'
                    }
                }
            },
            { id: '10',
                sortable: false,
                showFilterMatchModes: false,
                showFilterOperator: false,
                showAddButton: false,
                field: 'edit',
                title: '',
                type: 'button',
                icon: 'pi pi-pencil'
            },
        ]);

        provide('TableHeaders', headers.value);

        watch(() => showAddUser.value, (valNew, valOld) => {
            if (valOld) {
                userKey.value += 1;
                isEdit.value = false;
                userId.value = undefined;
            }
        })

        return {
            loadingData,
            users,
            showAddUser,
            isEdit,
            userId,
            userKey,
            blocked
        }
    }
});
